import React from "react";
import Layout from "../../components/layout/layout";
import Check from "../../assets/svg/icons-feather/check.svg";
import Icon from "../../components/icon";
import {Link} from "gatsby";
import "../landing-page.scss";

const IntrovertPower = () => {

  return (
    <Layout>
        <div className="landingpage-wrapper">

            <h1>Online course: The Power of Introversion</h1>

            <div className="block green top">
              <div className="text">
                <p>Are you an introvert and do you <strong>struggle</strong> with things like:</p>

                <ul className="checklist">
                  <li><Check width="32" height="32"/>being misunderstood</li>
                  <li><Check width="32" height="32"/>networking</li>
                  <li><Check width="32" height="32"/>dominant people</li>
                  <li><Check width="32" height="32"/>being overwhelmed</li>
                  <li><Check width="32" height="32"/>meetings</li>
                  <li><Check width="32" height="32"/>smalltalk</li>
                </ul>
              </div>
            </div>

           <div className="block with-bottom">
              <div className="pop"><Icon name="puzzlehead"/>Do you feel that you're not using your full potential?</div>
              <div className="text">
              <p>But you don't know how to get it out?</p>
              <p>Then this <strong>course</strong> is something for you.</p>
              <p>Watch the short video introduction:</p>
              </div>
            </div>

            <iframe title="youtube" width="100%" height="330" src="https://www.youtube-nocookie.com/embed/-eE4Y6WIm1k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <div className="block green top">
              <div className="text">
                
                <p>In this course you'll <strong>learn</strong>:</p>
                <ul className="checklist one-column">
                <li><Check width="32" height="32"/>what your unique strength is as an introvert</li>
                <li><Check width="32" height="32"/>how to deal with your unique challenges</li>
                <li><Check width="32" height="32"/>how to use your full potential</li>
                <li><Check width="32" height="32"/>how to make a future plan that works</li>
              </ul>
              </div>
            </div>

             <div className="block">
              <div className="pop">
                <Icon name="trust"/>About me
              </div>
              <div className="text left two-columns">
                <p>As a coach and trainer for introverts I always go <strong>deep</strong>. I do not believe that as a coach you should only work on the symptoms, but look for what lies beneath them. When you go deeper, you get to the real source of what is holding you back and you can really work on it.</p>
                <p>That depth is also exactly what I put into this training. You will learn more about what introversion is and you will also receive numerous models and tools to get you started. Obviously<strong>with some guidance</strong> from me, whenever you need it.</p>
                <p>I know the challenges described above very well. For years I felt <strong>misunderstood</strong> and did not understand how I could make full use of my talents. By doing a lot of soul-searching (including doing an MBA and working with a coach) I finally got the tools to do so. I now share this knowledge with you.</p>
                <p>Read more about me <Link to="/en/about">over mij</Link>.</p>
              </div>
            </div>  


            <div className="block">
              <div className="pop">
                <Icon name="finding"/>About this course
              </div>
              <div className="text left two-columns">
                <p>This course consists of <strong>videos</strong> with both theory and some <strong>exercises</strong> that help you to go deeper and <strong>four coaching sessions</strong> during which we will discuss the things you encounter during the training.</p>

                <p>You can go through the training at your own pace, where you decide when you do which module and when you want to have the coach calls. You'll get <strong>six months access to the learning tool</strong>, for you to have the possibility to look back after completing the training.</p>

              </div>
            </div>  



            <div className="block yellow">
              <div className="pop">
                <Icon name="badge"/>What you'll get
              </div>
              <div className="text left two-columns">
                <p>To celebrate the launch of this course, you can <strong>temporarily</strong><br/>buy it for &euro; 395,- (instead of &euro; 795,-).</p>
                <p>This offer is valid until June 1rst.</p>
                <p>This price is including VAT.</p>

                <div className="nobreak">
                  <ul className="checklist one-column">
                    <li><Check width="32" height="32"/>28 videos</li>
                    <li><Check width="32" height="32"/>your personal <strong>Big Five</strong> personality report</li>
                    <li><Check width="32" height="32"/>24 questions to reflect upon</li>
                    <li><Check width="32" height="32"/>7 exercises to go deeper</li>
                    <li><Check width="32" height="32"/>an overview of your unique strengths as an introvert</li>
                    <li><Check width="32" height="32"/>a future plan that works</li>
                    <li><Check width="32" height="32"/>4 <strong>coach calls</strong> of 1.5 hours each</li>
                  </ul>
                </div>
              </div>
            </div>  

            <div className="block">
              <div className="pop">
                <Icon name="detail"/>Course flow
              </div>
              <div className="text left two-columns">

            <ol>
              <li><strong>Introduction</strong>
              <ol>
                <li>Welcome to this course</li>
              </ol>
              </li>
              <li><strong>What is introversion?</strong>
              <ol>
                <li>The basis</li>
                <li>The MBTI approach</li>
                <li>The Big Five</li>
                <li>Neuroscience</li>
                <li>Biases on introversion</li>
              </ol>
              </li>
               <li><strong>The challenges of the introvert</strong>
              <ol>
                <li>Introduction</li>
                <li>Networking</li>
                <li>Meetings</li>
                <li>Conversations</li>
                <li>Dominant people</li>
              </ol>
              </li>
              <li><strong>The strength of the introvert</strong>
              <ol>
                 <li>Introduction</li>
                <li>Knowing your strength</li>
                <li>Your self-portrait</li>
                <li>Your blind spot​​​​​​​</li>
                <li>Show your strength</li>
              </ol>
              </li>
              <li><strong>Using your strength</strong>
              <ol>
                <li>Introduction</li>
                <li>Dealing with inner voices</li>
                <li>Balancing your energy</li>
                <li>Your personal growth plan​​​​​​​</li>
              </ol>
              </li>
              <li><strong>End and evaluation</strong>
              <ol>
                <li>Thank you</li>
                <li>Evaluation</li>
              </ol>
              </li>
            </ol>
              </div>
            </div>  

            <div className="block yellow">
              <div className="text one-column">
                <p><strong>The price for all of this is 395 euro.</strong></p>
                <p>Not satisfied? Then you can request a refund within seven days.</p>
              </div>
            </div>  


             <div className="block">
              <div className="pop">
                <Icon name="question"/>FAQ
              </div>
              <div className="text left one-column">
                <p><strong>I am not sure if I am introverted. Is this training for me?</strong></p>
                <p>This course gives you more information about what introversion is and how to measure it. Part of that is the Big Five personality test, which will tell you whether or not you are an introvert. So the training can at least answer that question.</p>
                <p><strong>Do I actually need coaching?</strong></p>
                <p>Experience shows that people who took part in this training enjoyed discussing their progress. This helped them to get more out of it and also learn more about themselves and how they can tackle certain challenges. It also creates commitment, so you will actually finish the training.</p>
                <p><strong>Is it worth the money?</strong></p>
                <p>Various (test) participants indicated that they found the course very valuable. It gave them answers to questions they had about themselves and helped them to move forward.</p>
                <p>In addition to online access, you also get six hours of coaching. This combination allows you to really apply what you have learned to your own situation and to work on the things you come across. That way it becomes a lot more personal. You normally pay 594 euros for six hours of coaching.</p>
                <p>Obviously, the experience is different for everyone and the center of gravity will be elsewhere for everyone. Not satisfied? Then you can request a refund within seven days after registration (if you have not yet completed all modules and coaching)</p>
                <p><strong>Is this really going to help me?</strong></p>
                <p>If you are looking for more clarity on your introverted side and also want to learn how to deal with your challenges and how to use your strength, then this training is definitely of added value for you. However, in coaching it is important that you are motivated to get started with working on the things you come across along the way. Only then will you actually achieve the results you are looking for.</p>
              </div>
            </div>  

            <div className="block yellow">
              <div className="text one-column">
                <p><strong>Are you ready to become the best version of you?</strong></p>
                <p><a href="/nl/contact">Get in touch with me</a> without any obligation.</p>
              </div>
            </div>  


      </div>
    </Layout>
  )
};

export default IntrovertPower;
